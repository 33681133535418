<template>
  <div>
    <b-overlay :show="loading">
      <form-wizard
        color="#ae2012"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Data Diri -->
        <tab-content title="Data Diri" :before-change="validationForm">
          <validation-observer ref="dataDiri" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <span class="mb-3">
                  Halo {{ user.name }}, Selamat datang di Pendaftaran SNBP,
                  Silakan isi data diri anda dengan benar</span
                >
                <br />
              </b-col>
              <b-col md="12">
                <b-form-group label="Nama" label-for="nama">
                  <validation-provider
                    #default="{ errors }"
                    name="nama"
                    rules="required"
                  >
                    <b-form-input
                      id="nama"
                      v-model="dataDiri.nama"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Nama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Asal Sekolah" label-for="asal_sekolah">
                  <validation-provider
                    #default="{ errors }"
                    name="asal_sekolah"
                    rules="required"
                  >
                    <b-form-input
                      id="asal_sekolah"
                      v-model="dataDiri.asal_sekolah"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Asal Sekolah"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Akreditasi Sekolah" label-for="ak_sekolah">
                  <validation-provider
                    #default="{ errors }"
                    name="Akreditasi Sekolah"
                    rules="required"
                  >
                    <b-form-input
                      id="ak_sekolah"
                      autocomplete="off"
                      v-model="dataDiri.ak_sekolah"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Akreditasi Sekolah"
                    />
                    <!-- <b-form-select id="asal_sekolah" v-model="dataDiri.ak_sekolah" :options="akreditasiOption" :state="errors.length > 0 ? false : null" /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Raport  -->
        <tab-content title="Raport" :before-change="validationFormRaport">
          <validation-observer ref="raport" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <span class="mb-3"
                  >Silakan Isi Nilai Raport dari Semester 1 sampai Semester
                  5</span
                >
                <br />
              </b-col>
              <b-col>
                <center>
                  <hr />
                  <h3>Nilai Raport</h3>
                  <hr />
                </center>
              </b-col>
            </b-row>
            <b-row v-for="(item, i) in mapelRaport" :key="i">
              <b-col md="12">
                <div
                  class="d-flex align-items-center justify-content-between mb-1"
                >
                  <label for="">
                    <strong>
                      Nilai Raport
                      <span class="text-primary">{{ item.nama_mapel }}</span>
                      (Semester 1 - 5)
                    </strong>
                  </label>
                  <b-tooltip :target="`label-hapus-${i}`" placement="top">
                    Hapus Mapel (karena tidak ada nilai mapel tersebut)
                  </b-tooltip>
                  <div
                    :id="`label-hapus-${i}`"
                    tabindex="0"
                    class="hapus-container"
                  >
                    <strong
                      class="d-block text-danger cursor-pointer"
                      @click="deleteSelectedMapel(i)"
                      >Hapus <feather-icon icon="AlertCircleIcon"></feather-icon
                    ></strong>
                  </div>
                </div>
              </b-col>
              <b-col md="2" v-for="(data, index) in item.semester" :key="index">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="`${item.nama_mapel} Semester  ${data.type}`"
                    rules="required|integer"
                  >
                    <b-form-input
                      :id="`nilai_raport`"
                      v-model="data.nilai"
                      @change="setRataRata(i, index)"
                      autocomplete="off"
                      :placeholder="`Nilai Semester ${data.type}`"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="`Rata-rata ${item.nama_mapel}`"
                    rules="required"
                  >
                    <b-form-input
                      readonly
                      :id="`rata-rata`"
                      v-model="item.rata_rata"
                      autocomplete="off"
                      :placeholder="`Rata-rata ${item.nama_mapel}`"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2"></b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Piagam -->
        <tab-content title="Piagam" :before-change="validationFormPiagam">
          <validation-observer
            ref="Piagam"
            tag="form"
            class="repeater-form"
            @submit.prevent="repeatePiagamAgain"
          >
            <h4 class="mb-0">
              <b> Silahkan isi piagam (<i class="text-danger">opsional</i>) </b>
            </h4>
            <small class="text-muted">*maksimal 3 piagam </small>

            <b-row
              class="mt-2"
              v-for="item in itemsPiagam"
              :id="item.id"
              :key="item.id"
              ref="rowPiagam"
            >
              <b-col md="6">
                <b-form-group label="Nama Piagam" label-for="piagam">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Piagam"
                    rules=""
                  >
                    <b-form-input
                      id="nilai_raport"
                      v-model="item.nama_piagam"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Nama Piagam "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="File Piagam" label-for="piagam">
                  <validation-provider
                    #default="{ errors }"
                    name="File Piagam"
                    rules=""
                  >
                    <b-form-file
                      v-model="item.file_piagam"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pilih file untuk upload piagam"
                      drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <center>
            <b-button
              v-if="itemsPiagam.length <= 2"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="repeatePiagamAgain"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Tambah</span>
            </b-button>
            <b-button
              v-if="itemsPiagam.length >= 2"
              class="mt-1 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              @click="removePiagamItem"
            >
              <feather-icon icon="TrashIcon" class="mr-25" />
              <span>Hapus</span>
            </b-button>
          </center>
        </tab-content>
        <!-- Alumni -->
        <!-- <tab-content title="Alumni" :before-change="validationFormAlumni">
        <validation-observer ref="Alumni" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2"> <span class="mb-3">Silakan Isi Alumni</span> <br /> </b-col>

            <b-col md="12">
              <center>
                <hr />
                <h3>Alumni</h3>
                <hr />
              </center>
            </b-col>
            <b-col md="12">
              <b-form-group label="Alumni" label-for="alumni">
                <validation-provider #default="{ errors }" name="Alumni" rules="required">
                  <b-form-select id="alumni" v-model="alumni.alumni" :options="alumniOption" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="alumni.alumni && alumni.alumni == 'ada'">
              <b-form-group label="Jumlah Alumni" label-for="ak_sekolah">
                <validation-provider #default="{ errors }" name="Jumlah Alumni" rules="required|integer">
                  <b-form-input id="jumlah_alumni" v-model="alumni.jumlah_alumni" :state="errors.length > 0 ? false : null" placeholder="Masukkan Jumlah Alumni" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content> -->
        <!-- Prodi -->
        <tab-content title="Prodi Pilihan" :before-change="validationFormProdi">
          <validation-observer
            ref="prodiPilihan"
            tag="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <h4 class="mb-0">
              <b> Silahkan pilih univertas dan jurusan yang dituju</b>
            </h4>
            <small class="text-muted">*maksimal 2 pilihan </small>

            <b-row
              class="mt-2"
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="12">
                <center>
                  <hr />
                  <h3>PILIHAN {{ item.no }}</h3>
                  <hr />
                </center>
              </b-col>
              <b-col md="6">
                <b-form-group label="Universitas" label-for="universitas">
                  <validation-provider
                    #default="{ errors }"
                    name="Universitas"
                    rules="required"
                  >
                    <v-select
                      id="universitas"
                      v-model="item.universitas"
                      @input="fetchDataJurusan(index)"
                      :options="universitasName"
                      label="text"
                      placeholder="Pilih Universitas"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Jurusan" label-for="jurusan">
                  <validation-provider
                    #default="{ errors }"
                    name="Jurusan"
                    rules="required"
                  >
                    <v-select
                      id="jurusan"
                      v-model="item.jurusan"
                      :options="item.jurusanName"
                      label="text"
                      placeholder="Pilih Jurusan"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Alumni" label-for="alumni">
                  <validation-provider
                    #default="{ errors }"
                    name="Alumni"
                    rules="required"
                  >
                    <b-form-select
                      id="alumni"
                      v-model="item.alumni"
                      :options="alumniOption"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="item.alumni && item.alumni == 'ada'">
                <b-form-group label="Jumlah Alumni" label-for="ak_sekolah">
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah Alumni"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="jumlah_alumni"
                      v-model="item.jumlah_alumni"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Masukkan Jumlah Alumni"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <center>
            <b-button
              v-if="items.length <= 1"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="repeateAgain"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Tambah</span>
            </b-button>
            <b-button
              v-if="items.length > 1"
              class="mt-1 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              @click="removeItem"
            >
              <feather-icon icon="TrashIcon" class="mr-25" />
              <span>Hapus</span>
            </b-button>
          </center>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BOverlay,
  BTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BOverlay,
    BTooltip,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      loading: false,
      pilihUniversitas: [],
      universitas_id: 0,
      pilihJurusan: [],
      nextTodoId: 1,
      universitas: null,
      items: [
        {
          no: 1,
          id: 1,
          universitas: null,
          jurusan: null,
          jurusanName: [],
          alumni: null,
          jumlah_alumni: 0,
        },
      ],
      itemsPiagam: [
        {
          id: 1,
          nama_piagam: null,
          file_piagam: null,
        },
      ],
      dataDiri: {
        nama: "",
        asal_sekolah: null,
        ak_sekolah: null,
      },
      alumni: {
        alumni: null,
        jumlah_alumni: 0,
      },

      selectedContry: "",
      selectedLanguage: "",
      name: "",
      emailValue: "",
      PasswordValue: "",
      passwordCon: "",
      first_name: "",
      last_name: "",
      address: "",
      landMark: "",
      pincode: "",
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      city: "",
      required,
      email,
      universitasName: [
        { value: "select_value", text: "Pilih Universitas" },
        { value: "Universitas Indonesia", text: "Universitas Indonesia" },
        { value: "Universitas Gadjah Mada", text: "Universitas Gadjah Mada" },
        { value: "Universitas Brawijaya", text: "Universitas Brawijaya" },
        { value: "Universitas Airlangga", text: "Universitas Airlangga" },
        { value: "Universitas Hasanuddin", text: "Universitas Hasanuddin" },
      ],
      jurusanName: [
        { value: "select_value", text: "Pilih Jurusan" },
        { value: "Teknik Sipil", text: "Teknik Sipil" },
        { value: "Teknik Informatika", text: "Teknik Informatika" },
        { value: "Agroteknologi", text: "Agroteknologi" },
        { value: "Ilmu Keperawatan", text: "Ilmu Keperawatan" },
        { value: "Geofisika", text: "Geofisika" },
      ],
      mapelRaport: [],
      akreditasiOption: [
        { value: null, text: "Masukkan Akreditasi Sekolah" },
        { value: "A", text: "A" },
        { value: "B", text: "B" },
        { value: "C", text: "C" },
      ],
      alumniOption: [
        { value: null, text: "Alumni" },
        { value: "ada", text: "Ada" },
        { value: "tidak ada", text: "Tidak Ada" },
      ],
    };
  },
  watch: {},
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  methods: {
    async fetchDataJurusan(index) {
      if (this.items[index].universitas != null) {
        this.items[index].jurusanName = await this.getDataJurusan(
          this.items[index].universitas.id
        );
      }
    },
    repeateAgain() {
      this.items.push({
        no: 2,
        id: (this.nextTodoId += this.nextTodoId),
        universitas: null,
        jurusan: null,
        jurusanName: [],
        alumni: null,
        jumlah_alumni: 0,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    repeatePiagamAgain() {
      this.itemsPiagam.push({
        id: (this.nextTodoId += this.nextTodoId),
        nama_piagam: null,
        file_piagam: null,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem() {
      this.$swal({
        title: "Anda Yakin ingin Menghapus?",
        text: "Data yang diinputkan akan hilang!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.items.splice(this.items.length - 1, 1);
          this.trTrimHeight(this.$refs.row[0].offsetHeight);
        }
      });
    },
    removePiagamItem() {
      this.$swal({
        title: "Anda Yakin ingin Menghapus?",
        text: "Data yang diinputkan akan hilang!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.itemsPiagam.splice(this.itemsPiagam.length - 1, 1);
          this.trTrimHeight(this.$refs.rowPiagam[0].offsetHeight);
        }
      });
    },
    formSubmitted() {
      let data = new FormData();
      data.append("user_id", this.user.id);
      data.append("name", this.dataDiri.nama);
      data.append("school_origin", this.dataDiri.asal_sekolah);
      data.append("school_accreditation", this.dataDiri.ak_sekolah);

      // loop through piagam dan check apakah ada yg diisi
      this.itemsPiagam.forEach((piagam, i) => {
        const notEmptyPiagam =
          ![null, "", undefined].includes(piagam.nama_piagam) &&
          piagam.file_piagam != null;
        if (notEmptyPiagam) {
          data.append(`piagam[${i}][name]`, piagam.nama_piagam);
          data.append(`piagam[${i}][file]`, piagam.file_piagam);
        }
      });
      var indexSemester = 0;
      let indexMapel = 0;
      let indexMapelCek = 0;
      let countMapel = this.mapelRaport.length * 5;
      for (let index = 0; index < countMapel; index++) {
        if (indexMapelCek > 4) {
          indexMapelCek = 0;
          indexMapel = indexMapel += 1;
        }
        let mapel = this.mapelRaport[indexMapel];

        if (indexSemester > 4) {
          indexSemester = 0;
        }
        let semester = mapel.semester[indexSemester];
        data.append(`nilai_semester[${index}][mapel_id]`, mapel.id);
        data.append(`nilai_semester[${index}][nilai]`, semester.nilai);
        data.append(`nilai_semester[${index}][type_semester]`, semester.type);
        indexMapelCek += 1;
        indexSemester += 1;
      }

      // this.mapelRaport.map((mapel, i) => {
      //   mapel.semester.map((semester) => {
      //     data.append(`nilai_semester[${i}][mapel_id]`, mapel.id);
      //     data.append(`nilai_semester[${i}][nilai]`, semester.nilai);
      //     data.append(`nilai_semester[${i}][type_semester]`, semester.type);
      //   });
      // });
      this.items.map((jurusan, i) => {
        data.append(`jurusan_pilihan[${i}][major_id]`, jurusan.jurusan.id);
        data.append(`jurusan_pilihan[${i}][pilihan_ke]`, jurusan.no);
        data.append(`jurusan_pilihan[${i}][sum_alumni]`, jurusan.jumlah_alumni);
      });
      this.loading = true;
      this.$store
        .dispatch("snbp/submit", data)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Berhasil`,
              icon: "CheckIcon",
              variant: "success",
              text: "Selamat anda berhasil mendaftar SNBP",
            },
          });
          this.$router.push({ name: "snbp" });
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.dataDiri.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormProdi() {
      return new Promise((resolve, reject) => {
        this.$refs.prodiPilihan.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormRaport() {
      return new Promise((resolve, reject) => {
        this.$refs.raport.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormPiagam() {
      return new Promise((resolve, reject) => {
        this.$refs.Piagam.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAlumni() {
      return new Promise((resolve, reject) => {
        this.$refs.Alumni.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getDataUniversitas() {
      this.$store.dispatch("masterUniv/index", {}).then((response) => {
        let universitas = response.data.data;
        universitas.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.universitasName = universitas;
      });
    },
    async getDataJurusan(universitas_id) {
      let params = {
        school_id: universitas_id,
        for_snbp: 1,
      };
      let jurusans = await this.$store.dispatch("jurusan/index", params);
      let jurusan = jurusans.data.data;
      jurusan.map((item) => {
        item.value = item.id;
        item.text = item.name;
      });
      return jurusan;
    },
    getDataMapel() {
      this.$store.dispatch("mapel/index", {}).then((response) => {
        let mapel = response.data.data;
        mapel.map((item) => {
          item.semester = [
            {
              type: 1,
              nilai: 0,
            },
            {
              type: 2,
              nilai: 0,
            },
            {
              type: 3,
              nilai: 0,
            },
            {
              type: 4,
              nilai: 0,
            },
            {
              type: 5,
              nilai: 0,
            },
          ];
          item.rata_rata = null;
        });
        this.mapelRaport = mapel;
      });
    },
    deleteSelectedMapel(index) {
      if (this.mapelRaport.length < 1) {
        this.displayError({
          message: "Anda tidak bisa menghapus semua mata pelajaran!",
        });
        return false;
      }

      let mapel = Object.assign([], this.mapelRaport);
      mapel.splice(index, 1);
      this.mapelRaport = mapel;
    },
    setRataRata(indexMapel, indexSemester) {
      let mapel = this.mapelRaport[indexMapel];
      let nilai_semester1 = mapel.semester[0].nilai;
      let nilai_semester2 = mapel.semester[1].nilai;
      let nilai_semester3 = mapel.semester[3].nilai;
      let nilai_semester4 = mapel.semester[4].nilai;
      let nilai_semester5 = mapel.semester[4].nilai;
      let rata_rata =
        (parseInt(nilai_semester1) +
          parseInt(nilai_semester2) +
          parseInt(nilai_semester3) +
          parseInt(nilai_semester4) +
          parseInt(nilai_semester5)) /
        5;
      this.mapelRaport[indexMapel].rata_rata = rata_rata;
    },
  },
  created() {
    this.dataDiri.nama = this.user.name;
    this.dataDiri.asal_sekolah = this.user.detail.school_origin_info
      ? this.user.detail.school_origin_info.nama_sekolah
      : "-";
    this.getDataUniversitas();
    this.getDataMapel();
  },
};
</script>
